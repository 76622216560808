import Dashboard from "layouts/dashboard";
import Binance from "layouts/binance";
import OKX from "layouts/okx";
import Bybit from "layouts/bybit";
import Settings from "layouts/settings";
import SignIn from "layouts/authentication/sign-in";

import Icon from "@mui/material/Icon";


const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">groups</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Binance",
    key: "binance",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/binance",
    component: <Binance />,
  },
  {
    type: "collapse",
    name: "Bybit",
    key: "bybit",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/bybit",
    component: <Bybit />,
  },
  {
    type: "collapse",
    name: "OKX",
    key: "okx",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/okx",
    component: <OKX />,
  },
  {
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/settings",
    component: <Settings />,
  },
  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  }
];

export default routes;
