import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import MDTypography from "components/MDTypography";
import Grid2 from "@mui/material/Unstable_Grid2";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DataTable from "examples/Tables/DataTable";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from "@mui/material/Tooltip";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function Dashboard() {
    const columns = [
        { Header: "nickcname", accessor: "nickcname", align: "center" },
        { Header: "exchange", accessor: "exchange", align: "center" },
        { Header: "side", accessor: "side", align: "center" },
        { Header: "payment methods", accessor: "payment_methods", align: "center" },
        { Header: "ticker", accessor: "ticker", align: "center" },
        { Header: "price", accessor: "price", align: "center" },
        { Header: "position", accessor: "id", align: "center" },
    ];
    const [btcPrice, setBtcPrice] = useState("");
    const [crossPrice, setCrossPrice] = useState("");

    useEffect(() => {
        document.title = "Dashboard | P2P Bot";
    }, []);

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem("_token");

            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}/dashboard`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                const jwtToken = response.headers["authorization"];
                if (jwtToken) {
                    const parts = jwtToken.split(" ");
                    const token = parts[1];
                    localStorage.setItem("_token", token);
                } else {
                    navigate("/sign-in");
                }

                const data = response.data.dashboard;
                setBtcPrice(response.data.btc_uah_price);
                setCrossPrice(response.data.cross_price);

                const formattedRows = data.map((app) => ({
                    nickcname: (
                        <MDTypography component="span" variant="caption" fontWeight="medium" key={app.trader_nickname}>
                            {app.trader_nickname}
                        </MDTypography>
                    ),
                    exchange: (
                        <MDTypography component="span" variant="caption" fontWeight="medium" key={app.trader_nickname}>
                            {app.exchange}
                        </MDTypography>
                    ),
                    side: (
                        <MDTypography component="span" variant="caption" fontWeight="medium" key={app.side}>
                            {app.side}
                        </MDTypography>
                    ),
                    payment_methods: (
                        <Grid2 container direction="column" key={app.id}>
                            {app.payment_methods.map((method, index) => (
                                <Grid2 key={method}>
                                    <MDTypography key={index} component="span" variant="caption" fontWeight="medium">
                                        {method}
                                    </MDTypography>
                                </Grid2>
                            ))}
                        </Grid2>
                    ),
                    ticker: (
                        <MDTypography component="span" variant="caption" fontWeight="medium" key={app.ticker}>
                            {app.ticker}
                        </MDTypography>
                    ),
                    price: (
                        <Grid container direction="row" spacing={1}>
                            <Grid item>
                                <MDTypography component="span" variant="caption" fontWeight="medium" key={app.price}>
                                    {app.price}
                                </MDTypography>
                            </Grid>
                            <Grid item sx={1} width="40%">
                                <MDInput
                                    key={app.id}
                                    name="change_price"
                                    type="number"
                                    label="%"
                                    step="0.1"
                                    min="0"
                                // onChange={handleInputChange}
                                // value={formData.apkName}
                                />
                            </Grid>
                        </Grid>
                    ),
                    id: (
                        <MDTypography component="span" variant="caption" fontWeight="medium">
                            {app.id}
                        </MDTypography>
                    )
                }));

                setRows(formattedRows);

            } catch (error) {
                if (error.code === "ERR_NETWORK") {
                    enqueueSnackbar("No connection with server", {
                        variant: "error",
                        persist: true,
                        anchorOrigin: { horizontal: "center", vertical: "top" },
                    });
                } else if (error.response.status === 401) {
                    navigate("/sign-in");
                }
            }
        };
        fetchData();

        const intervalId = setInterval(fetchData, 120 * 1000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={1} pb={1}>
                <Grid container direction="row">
                    <Grid item xs={4}>
                        <MDTypography component="span" variant="subtitle1" color="text" fontWeight="medium">
                            BTC / UAH: {btcPrice}
                        </MDTypography>
                    </Grid>
                    <Grid item xs={4}>
                        <MDTypography component="span" variant="subtitle1" color="text" fontWeight="medium">
                            BTC-USDT-UAH: {crossPrice}
                        </MDTypography>
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox pt={1} pb={1}>
                <Accordion sx={{ backgroundColor: "inherit", border: "solid 2px", borderRadius: "30px", borderColor: "secondary.main" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{ color: "secondary.main" }} />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ color: "secondary.main" }}
                    >
                        Filters
                    </AccordionSummary>
                    <AccordionDetails>
                        <MDBox>
                            <Card>
                                <MDBox m={2}>
                                    <Grid container spacing={2} direction="row">
                                        <Grid item xs={2}>
                                            <Grid container spacing={2} direction="column">
                                                <Grid item xs={4}>
                                                    <Tooltip title="Minimum UAH amount in order for concurrent" placement="top">
                                                        <MDInput
                                                            name="min_volume"
                                                            type="text"
                                                            label="Volume min."
                                                        // onChange={handleInputChange}
                                                        // value={formData.apkName}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <MDInput
                                                        name="price_min"
                                                        type="text"
                                                        label="Price min."
                                                    // onChange={handleInputChange}
                                                    // value={formData.apkName}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Grid container spacing={2} direction="column">
                                                <Grid item xs={4}>
                                                    <Tooltip title="Maximum UAH amount in order for concurrent" placement="top">
                                                        <MDInput
                                                            name="max_volume"
                                                            type="text"
                                                            label="Volume max."
                                                        // onChange={handleInputChange}
                                                        // value={formData.apkName}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <MDInput
                                                        name="price_max"
                                                        type="text"
                                                        label="Price max."
                                                    // onChange={handleInputChange}
                                                    // value={formData.apkName}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="Monobank" />
                                                <FormControlLabel control={<Checkbox />} label="PrivatBank" />
                                                <FormControlLabel control={<Checkbox />} label="PUMBBank" />
                                                <FormControlLabel control={<Checkbox />} label="ABank" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="RaiffeisenBankAval" />
                                                <FormControlLabel control={<Checkbox />} label="Oschadbank" />
                                                <FormControlLabel control={<Checkbox />} label="izibank" />
                                                <FormControlLabel control={<Checkbox />} label="SenseSuperApp" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="BankVlasnyiRakhunok" />
                                                <FormControlLabel control={<Checkbox />} label="OTPBankNew" />
                                                <FormControlLabel control={<Checkbox />} label="Ukrsibbank" />
                                                <FormControlLabel control={<Checkbox />} label="Monobankiban" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="IdeaBank" />
                                                <FormControlLabel control={<Checkbox />} label="Ukrgasbank" />
                                                <FormControlLabel control={<Checkbox />} label="CreditAgricole" />
                                                <FormControlLabel control={<Checkbox />} label="PUMBIBAN" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="BankIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="KredoBank" />
                                                <FormControlLabel control={<Checkbox />} label="AbankIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="Tascombank" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="BankPivdenny" />
                                                <FormControlLabel control={<Checkbox />} label="AccordBank" />
                                                <FormControlLabel control={<Checkbox />} label="BankCreditDnipro" />
                                                <FormControlLabel control={<Checkbox />} label="Sportbank" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="PravexBank" />
                                                <FormControlLabel control={<Checkbox />} label="Ukreximbank" />
                                                <FormControlLabel control={<Checkbox />} label="SenseSuperAppIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="GEOPay" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="RaiffeisenAvalIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="OschadBankIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="PiraeusBank" />
                                                <FormControlLabel control={<Checkbox />} label="IzibankIBAN" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="Radabank" />
                                                <FormControlLabel control={<Checkbox />} label="CystalBank" />
                                                <FormControlLabel control={<Checkbox />} label="OTPBANKIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="Ukrsibbankiban" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="BankVlasnyiIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="IdeaBankIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="TascombankIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="BankCreditDniproIBAN" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="BankPivdennyIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="KredoBankIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="AccordbankIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="CreditAgricoleIBAN" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="SettlePay" />
                                                <FormControlLabel control={<Checkbox />} label="SportbankIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="UkrgasbankIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="JordanIslamicBank" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox />} label="PravexBankIBAN" />
                                                <FormControlLabel control={<Checkbox />} label="UkreximbankIBAN" />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Tooltip title="Order executing time filter" placement="top">
                                                <MDTypography variant="h6">
                                                    Time filter
                                                </MDTypography>
                                                <FormGroup>
                                                    <FormControlLabel control={<Checkbox />} label="To 15 minutes" />
                                                    <FormControlLabel control={<Checkbox />} label="To 30 minutes" />
                                                    <FormControlLabel control={<Checkbox />} label="To 180 minutes" />
                                                </FormGroup>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </Card>
                        </MDBox>
                    </AccordionDetails>
                </Accordion>
            </MDBox>
            <MDBox pt={1} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox pt={3}>
                                <DataTable
                                    table={{ columns, rows }}
                                    isSorted={false}
                                    entriesPerPage={false}
                                    showTotalEntries={true}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default Dashboard;
