import { useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import ETHPage from "./components/eth";
import USDTPage from "./components/usdt";
import USDCPage from "./components/usdc";
import BTCPage from "./components/btc";
import TONPage from "./components/ton";
import MDTypography from "components/MDTypography";
import Grid2 from "@mui/material/Unstable_Grid2";
import MDInput from "components/MDInput";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "OKX";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token`, {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        const jwtToken = response.headers["authorization"];

        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }
      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          enqueueSnackbar("No connection with server", {
            variant: "error",
            persist: true,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function OKX() {
  const defaultTab = 0;
  const [value, setValue] = useState(defaultTab);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  
  const [usernameFilter, setUsernameFilter] = useState(localStorage.getItem("okx_nickname") || "");
  const [minVolumeFilter, setMinVolumeFilter] = useState(localStorage.getItem("okx_volume_min") || "");
  const [maxVolumeFilter, setMaxVolumeFilter] = useState(localStorage.getItem("okx_volume_max") || "");
  const [paymentMethodFilter, setPaymentMethodFilter] = useState(localStorage.getItem("okx_payment") || "");
  
  const [usdtBuyRows, setUsdtBuyRows] = useState([]);
  const [usdtSellRows, setUsdtSellRows] = useState([]);
  const [usdcBuyRows, setUsdcBuyRows] = useState([]);
  const [usdcSellRows, setUsdcSellRows] = useState([]);
  const [btcBuyRows, setBtcBuyRows] = useState([]);
  const [btcSellRows, setBtcSellRows] = useState([]);
  const [tonBuyRows, setTonBuyRows] = useState([]);
  const [tonSellRows, setTonSellRows] = useState([]);
  const [ethBuyRows, setEthBuyRows] = useState([]);
  const [ethSellRows, setEthSellRows] = useState([]);

  const onChangeUsernameFilter = (newValue) => {
    setUsernameFilter(newValue);
    localStorage.setItem('okx_nickname', newValue);
  };
  
  const onChangeVolumeMinFilter = (newValue) => {
    setMinVolumeFilter(newValue);
    localStorage.setItem('okx_volume_min', newValue);
  };
  
  const onChangeVolumeMaxFilter = (newValue) => {
    setMaxVolumeFilter(newValue);
    localStorage.setItem('okx_volume_max', newValue);
  };
  
  const onChangeVolumePaymentFilter = (newValue) => {
    setPaymentMethodFilter(newValue);
    localStorage.setItem('okx_payment', newValue);
  };
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('activeTab', newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");

      const nickname = localStorage.getItem("okx_nickname") || null;
      const volume_min = localStorage.getItem("okx_volume_min") || null;
      const volume_max = localStorage.getItem("okx_volume_max") || null;
      const payment = localStorage.getItem("okx_payment") || null;

      const params = new URLSearchParams();
      if (nickname) params.append('nickname', nickname);
      if (volume_min) params.append('volume_min', volume_min);
      if (volume_max) params.append('volume_max', volume_max);
      if (payment) params.append('payment', payment);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/exchanges/okx?${params.toString()}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const jwtToken = response.headers["authorization"];
        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }

        const usdt_data_buy = response.data.USDT.BUY;
        const usdt_data_sell = response.data.USDT.SELL;
        const usdc_data_buy = response.data.USDC.BUY;
        const usdc_data_sell = response.data.USDC.SELL;
        const btc_data_buy = response.data.BTC.BUY;
        const btc_data_sell = response.data.BTC.SELL;
        const ton_data_buy = response.data.TON.BUY;
        const ton_data_sell = response.data.TON.SELL;
        const eth_data_buy = response.data.ETH.BUY;
        const eth_data_sell = response.data.ETH.SELL;

        const usdtBuyFormattedRows = usdt_data_buy.map((app) => ({
          id: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.id}
            </MDTypography>
          ),
          nickcname: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.trader_nickname}>
              {app.trader_nickname}
            </MDTypography>
          ),
          price: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.price}>
              {app.price}
            </MDTypography>
          ),
          volume: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.volume}>
              {app.min_trade_amount} - {app.max_trade_amount}
            </MDTypography>
          ),
          payment_methods: (
            <Grid2 container direction="column" key={app.id}>
              {app.payment_methods.map((method, index) => (
                <Grid2 key={method}>
                  <MDTypography key={index} component="span" variant="caption" color={app.color} fontWeight="medium">
                    {method}
                  </MDTypography>
                </Grid2>
              ))}
            </Grid2>
          ),
        }));

        const usdtSellFormattedRows = usdt_data_sell.map((app) => ({
          id: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.id}
            </MDTypography>
          ),
          nickcname: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.trader_nickname}>
              {app.trader_nickname}
            </MDTypography>
          ),
          price: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.price}>
              {app.price}
            </MDTypography>
          ),
          volume: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.volume}>
              {app.min_trade_amount} - {app.max_trade_amount}
            </MDTypography>
          ),
          payment_methods: (
            <Grid2 container direction="column" key={app.id}>
              {app.payment_methods.map((method, index) => (
                <Grid2 key={method}>
                  <MDTypography key={index} component="span" variant="caption" color={app.color} fontWeight="medium">
                    {method}
                  </MDTypography>
                </Grid2>
              ))}
            </Grid2>
          ),
        }));

        const usdcBuyFormattedRows = usdc_data_buy.map((app) => ({
          id: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.id}
            </MDTypography>
          ),
          nickcname: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.trader_nickname}>
              {app.trader_nickname}
            </MDTypography>
          ),
          price: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.price}>
              {app.price}
            </MDTypography>
          ),
          volume: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.volume}>
              {app.min_trade_amount} - {app.max_trade_amount}
            </MDTypography>
          ),
          payment_methods: (
            <Grid2 container direction="column" key={app.id}>
              {app.payment_methods.map((method, index) => (
                <Grid2 key={method}>
                  <MDTypography key={index} component="span" variant="caption" color={app.color} fontWeight="medium">
                    {method}
                  </MDTypography>
                </Grid2>
              ))}
            </Grid2>
          ),
        }));

        const usdcSellFormattedRows = usdc_data_sell.map((app) => ({
          id: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.id}
            </MDTypography>
          ),
          nickcname: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.trader_nickname}>
              {app.trader_nickname}
            </MDTypography>
          ),
          price: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.price}>
              {app.price}
            </MDTypography>
          ),
          volume: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.volume}>
              {app.min_trade_amount} - {app.max_trade_amount}
            </MDTypography>
          ),
          payment_methods: (
            <Grid2 container direction="column" key={app.id}>
              {app.payment_methods.map((method, index) => (
                <Grid2 key={method}>
                  <MDTypography key={index} component="span" variant="caption" color={app.color} fontWeight="medium">
                    {method}
                  </MDTypography>
                </Grid2>
              ))}
            </Grid2>
          ),
        }));

        const btcBuyFormattedRows = btc_data_buy.map((app) => ({
          id: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.id}
            </MDTypography>
          ),
          nickcname: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.trader_nickname}
            </MDTypography>
          ),
          price: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.price}
            </MDTypography>
          ),
          volume: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.min_trade_amount} - {app.max_trade_amount}
            </MDTypography>
          ),
          payment_methods: (
            <Grid2 container direction="column" key={app.id}>
              {app.payment_methods.map((method, index) => (
                <Grid2 key={index}>
                  <MDTypography key={index} component="span" variant="caption" color={app.color} fontWeight="medium">
                    {method}
                  </MDTypography>
                </Grid2>
              ))}
            </Grid2>
          ),
        }));

        const btcSellFormattedRows = btc_data_sell.map((app) => ({
          id: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {app.id}
            </MDTypography>
          ),
          nickcname: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {app.trader_nickname}
            </MDTypography>
          ),
          price: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {app.price}
            </MDTypography>
          ),
          volume: (
            <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
              {app.min_trade_amount} - {app.max_trade_amount}
            </MDTypography>
          ),
          payment_methods: (
            <Grid2 container direction="column" key={app.id}>
              {app.payment_methods.map((method, index) => (
                <Grid2 key={index}>
                  <MDTypography key={index} component="span" variant="caption" color="text" fontWeight="medium">
                    {method}
                  </MDTypography>
                </Grid2>
              ))}
            </Grid2>
          ),
        }));

        const ethBuyFormattedRows = eth_data_buy.map((app) => ({
          id: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.id}
            </MDTypography>
          ),
          nickcname: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.trader_nickname}
            </MDTypography>
          ),
          price: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.price}
            </MDTypography>
          ),
          volume: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.min_trade_amount} - {app.max_trade_amount}
            </MDTypography>
          ),
          payment_methods: (
            <Grid2 container direction="column" key={app.id}>
              {app.payment_methods.map((method, index) => (
                <Grid2 key={index}>
                  <MDTypography key={index} component="span" variant="caption" color={app.color} fontWeight="medium">
                    {method}
                  </MDTypography>
                </Grid2>
              ))}
            </Grid2>
          ),
        }));

        const ethSellFormattedRows = eth_data_sell.map((app) => ({
          id: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.id}
            </MDTypography>
          ),
          nickcname: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.trader_nickname}
            </MDTypography>
          ),
          price: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.price}
            </MDTypography>
          ),
          volume: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.min_trade_amount} - {app.max_trade_amount}
            </MDTypography>
          ),
          payment_methods: (
            <Grid2 container direction="column" key={app.id}>
              {app.payment_methods.map((method, index) => (
                <Grid2 key={index}>
                  <MDTypography key={index} component="span" variant="caption" color={app.color} fontWeight="medium">
                    {method}
                  </MDTypography>
                </Grid2>
              ))}
            </Grid2>
          ),
        }));

        const tonBuyFormattedRows = ton_data_buy.map((app) => ({
          id: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.id}
            </MDTypography>
          ),
          nickcname: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.trader_nickname}
            </MDTypography>
          ),
          price: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.price}
            </MDTypography>
          ),
          volume: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.min_trade_amount} - {app.max_trade_amount}
            </MDTypography>
          ),
          payment_methods: (
            <Grid2 container direction="column" key={app.id}>
              {app.payment_methods.map((method, index) => (
                <Grid2 key={index}>
                  <MDTypography key={index} component="span" variant="caption" color={app.color} fontWeight="medium">
                    {method}
                  </MDTypography>
                </Grid2>
              ))}
            </Grid2>
          ),
        }));

        const tonSellFormattedRows = ton_data_sell.map((app) => ({
          id: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.id}
            </MDTypography>
          ),
          nickcname: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.trader_nickname}
            </MDTypography>
          ),
          price: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.price}
            </MDTypography>
          ),
          volume: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium">
              {app.min_trade_amount} - {app.max_trade_amount}
            </MDTypography>
          ),
          payment_methods: (
            <Grid2 container direction="column" key={app.id}>
              {app.payment_methods.map((method, index) => (
                <Grid2 key={index}>
                  <MDTypography key={index} component="span" variant="caption" color={app.color} fontWeight="medium">
                    {method}
                  </MDTypography>
                </Grid2>
              ))}
            </Grid2>
          ),
        }));

        setUsdtBuyRows(usdtBuyFormattedRows);
        setUsdtSellRows(usdtSellFormattedRows);
        setUsdcBuyRows(usdcBuyFormattedRows);
        setUsdcSellRows(usdcSellFormattedRows);
        setBtcBuyRows(btcBuyFormattedRows);
        setBtcSellRows(btcSellFormattedRows);
        setTonBuyRows(tonBuyFormattedRows);
        setTonSellRows(tonSellFormattedRows);
        setEthBuyRows(ethBuyFormattedRows);
        setEthSellRows(ethSellFormattedRows);

      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          enqueueSnackbar("No connection with server", {
            variant: "error",
            persist: true,
            anchorOrigin: { horizontal: "center", vertical: "top" },
          });
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();
  }, [usernameFilter, minVolumeFilter, maxVolumeFilter, paymentMethodFilter]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid2 container direction="row" spacing={2} ml={3} mb={2}>
        <Grid2>
          <MDInput
            ml={2}
            placeholder="Nickname"
            value={usernameFilter}
            onChange={(e) => onChangeUsernameFilter(e.target.value)}
          />
        </Grid2>
        <Grid2>
          <MDInput
            ml={2}
            placeholder="Volume min"
            value={minVolumeFilter}
            onChange={(e) => onChangeVolumeMinFilter(e.target.value)}
          />
        </Grid2>
        <Grid2>
          <MDInput
            ml={2}
            placeholder="Volume max"
            value={maxVolumeFilter}
            onChange={(e) => onChangeVolumeMaxFilter(e.target.value)}
          />
        </Grid2>
        <Grid2>
          <MDInput
            ml={2}
            placeholder="Payment Method"
            value={paymentMethodFilter}
            onChange={(e) => onChangeVolumePaymentFilter(e.target.value)}
          />
        </Grid2>
      </Grid2>
      <MDBox mt={1} mb={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="info"
          aria-label="tabs">
          <Tab label="USDT" {...a11yProps(0)} />
          <Tab label="USDC" {...a11yProps(1)} />
          <Tab label="ETH" {...a11yProps(2)} />
          <Tab label="BTC" {...a11yProps(3)} />
          <Tab label="TON" {...a11yProps(4)} />
        </Tabs>
      </MDBox>
      <TabPanel value={value} index={0}>
        <USDTPage buyRows={usdtBuyRows} sellRows={usdtSellRows} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <USDCPage buyRows={usdcBuyRows} sellRows={usdcSellRows} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ETHPage buyRows={ethBuyRows} sellRows={ethSellRows} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <BTCPage buyRows={btcBuyRows} sellRows={btcSellRows} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TONPage buyRows={tonBuyRows} sellRows={tonSellRows} />
      </TabPanel>
    </DashboardLayout>
  );
}

export default OKX;
