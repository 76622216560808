import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import SellPage from "./components/sell";
import BuyPage from "./components/buy";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 2 }}>{children}</MDBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function BTCPage({ buyRows, sellRows }) {
  const defaultTab = 0;
  const [value, setValue] = useState(defaultTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('activeTab', newValue);
  };

  useEffect(() => {
    document.title = "Binance | BTC | Sell";
  }, []);

  return (
    <>
      <MDBox mb={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="info"
          aria-label="tabs">
          <Tab label="SELL" {...a11yProps(0)} />
          <Tab label="BUY" {...a11yProps(1)} />
        </Tabs>
      </MDBox>
      <TabPanel value={value} index={0}>
        <SellPage rows={sellRows} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BuyPage rows={buyRows} />
      </TabPanel>
    </>
  );
}

export default BTCPage;
