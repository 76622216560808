import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "../../../../components/MDButton";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import MDInput from "components/MDInput";
import {useSnackbar} from "notistack";
import axios from "axios";

function ExchangesAccountsCreator() {
  const [exchangeName, setExchangeName] = useState("");
  const [accountNickname, setAccountNickname] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const addNewAccountClick = async () => {
    const token = localStorage.getItem("_token");
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/settings/account`, {
        exchange_name: exchangeName,
        nickname: accountNickname,
      },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      if (error.code === "ERR_NETWORK") {
        enqueueSnackbar("No connection with server", {
          variant: "error",
          persist: true,
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      } else if (error.response.status === 401) {
        navigate("/sign-in");
      } else if (error.response.status === 400) {
        enqueueSnackbar("Password is invalid", {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "top" },
        });
      }
    }
    setAccountNickname("");
    setExchangeName("");
  };

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Add New Exchange Account
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          Add account
        </MDTypography>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={2}>
            <MDInput
              type="text"
              label="Exchange name"
              value={exchangeName}
              onChange={(e) => setExchangeName(e.target.value)}
            />
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <MDBox mt={1}>
            <MDInput
              type="text"
              label="Account nickname"
              value={accountNickname}
              onChange={(e) => setAccountNickname(e.target.value)}
            />
          </MDBox>
        </MDBox>
        <MDBox display="flex" alignItems="center">
          <MDBox mt={2}>
            <MDButton
              variant="outlined"
              color="info"
              size="medium"
              onClick={addNewAccountClick}
            >
              Add account
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default ExchangesAccountsCreator;
