import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";

function SellPage({ rows }) {
  const columns = [
    { Header: "#", accessor: "id", align: "center" },
    { Header: "nickcname", accessor: "nickcname", align: "center" },
    { Header: "price", accessor: "price", align: "center" },
    { Header: "volume", accessor: "volume", align: "center" },
    { Header: "payment methods", accessor: "payment_methods", align: "center" },
  ];

  useEffect(() => {
    document.title = "Binance | FDUSD | Sell";
  }, []);

  return (
    <MDBox pt={1} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={true}
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default SellPage;
