import { useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";

function ExchangesInfo() {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const columns = [
    { Header: "exchange", accessor: "exchange", align: "center" },
    { Header: "nickname", accessor: "nickname", align: "center" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("_token");
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/settings`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const jwtToken = response.headers["authorization"];
        if (jwtToken) {
          const parts = jwtToken.split(" ");
          const token = parts[1];
          localStorage.setItem("_token", token);
        } else {
          navigate("/sign-in");
        }

        const rows = response.data.data;

        const formattedRows = rows.map((app) => ({
          exchange: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.trader_nickname}>
              {app.exchange_name}
            </MDTypography>
          ),
          nickname: (
            <MDTypography component="span" variant="caption" color={app.color} fontWeight="medium" key={app.price}>
              {app.nickname}
            </MDTypography>
          ),
        }));

        setRows(formattedRows);

      } catch (error) {
        if (error.code === "ERR_NETWORK") {
          console.error("Error connections")
        } else if (error.response.status === 401) {
          navigate("/sign-in");
        }
      }
    };
    fetchData();
  }, []);

  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox pt={3}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={true}
        />
      </MDBox>
    </Card>
  );
}

export default ExchangesInfo;
